// 导出页面为PDF格式
import html2Canvas from 'html2canvas';
import JsPDF from 'jspdf';
// import 'jspdf-autotable'

export function getPdf(title, id) {
    console.log('导出pdf')
    html2Canvas(document.querySelector(`#${id}`), {
        backgroundColor: '#fff',
        // useCORS: true,
        allowTaint: false // 允许跨域
    }).then(function (canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        // 根据A4纸的大小，计算出dom相应比例的尺寸
        let pageHeight = contentWidth / 592.28 * 841.89;
        let leftHeight = contentHeight;
        let position = 20;
        let imgWidth = 555.28;
        // 根据a4比例计算出需要分割的实际dom位置
        let imgHeight = 555.28 / contentWidth * contentHeight;
        // canvas绘图生成image数据，1.0是质量参数
        let pageData = canvas.toDataURL('image/jpeg', 1.0);
        // a4大小
        let PDF = new JsPDF('', 'pt', 'a4');
        // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
        if (leftHeight < pageHeight) {
            PDF.addImage(pageData, 'JPEG', 20, 20, imgWidth, imgHeight);
        } else {
            while (leftHeight > 0) {
                PDF.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight);
                leftHeight -= pageHeight;
                position -= 841.89;
                if (leftHeight > 0) {
                    PDF.addPage();
                }
            }
        }
        // 导出
        PDF.save(title + '.pdf');
    })
}
// export function getPdf(title, id) {
//     // let title = title
//     // 如果这个页面有左右移动,canvas 也要做响应的移动，不然会出现canvas 内容不全
//     const xOffset = window.pageXOffset
//     // 避免笔下误 灯下黑 统一写
//     const A4_WIDTH = 592.28
//     const A4_HEIGHT = 880
//     let printDom = document.querySelector(`#${id}`)
//     // 根据A4的宽高计算DOM页面一页应该对应的高度
//     let pageHeight = printDom.offsetWidth / A4_WIDTH * A4_HEIGHT
//     // 将所有不允许被截断的元素进行处理
//     let wholeNodes = document.querySelectorAll('.myChart')
//     for (let i = 0; i < wholeNodes.length; i++) {
//         //1、 判断当前的不可分页元素是否在两页显示
//         const topPageNum = Math.ceil((wholeNodes[i].offsetTop) / pageHeight)

//         const bottomPageNum = Math.ceil((wholeNodes[i].offsetTop + wholeNodes[i].offsetHeight) / pageHeight);
//         //   const bottomPageNum = Math.ceil((wholeNodes[i].offsetTop + wholeNodes[i].offsetHeight) / pageHeight)

//         if (topPageNum !== bottomPageNum) {
//             //说明该dom会被截断
//             // 2、插入空白块使被截断元素下移
//             let divParent = wholeNodes[i].parentNode
//             let newBlock = document.createElement('div')
//             newBlock.className = 'emptyDiv'
//             newBlock.style.background = '#fff'

//             // 3、计算插入空白块的高度 可以适当流出空间使得内容太靠边，根据自己需求而定
//             let _H = topPageNum * pageHeight - wholeNodes[i].offsetTop
//             newBlock.style.height = _H + 30 + 'px'
//             divParent.insertBefore(newBlock, wholeNodes[i])
//         }
//         // 以上完成dom层面的分页 可以转为图片进一步处理了
//         html2Canvas(printDom, {
//             height: printDom.offsetHeight,
//             width: printDom.offsetWidth,
//             scrollX: -xOffset,
//             allowTaint: false
//         }).then(canvas => {
//             //dom 已经转换为canvas 对象，可以将插入的空白块删除了
//             let emptyDivs = document.querySelectorAll('.emptyDiv')
//             for (let i = 0; i < emptyDivs.length; i++) {
//                 emptyDivs[i].parentNode.removeChild(emptyDivs[i])
//             }
//             // 有一点重复的代码
//             let contentWidth = canvas.width
//             let contentHeight = canvas.height
//             let pageHeight = contentWidth / A4_WIDTH * A4_HEIGHT
//             let leftHeight = contentHeight
//             let position = 0

//             let imgWidth = A4_WIDTH
//             let imgHeight = A4_WIDTH / contentWidth * contentHeight
//             let pageData = canvas.toDataURL('image/jpeg', 1.0)
//             // if (isPrint) {
//             //   //如果是打印，可以拿着分号页的数据 直接使用
//             //   printJs({ printable: pageData, type: 'image', base64: true, documentTitle: '\u200E' })
//             //   return
//             // }
//             //计算分页的pdf 
//             let PDF = new JsPDF('', 'pt', 'a4')
//             if (leftHeight <= pageHeight) {
//                 PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
//             } else {
//                 while (leftHeight > 0) {
//                     PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
//                     leftHeight -= pageHeight
//                     position -= A4_HEIGHT
//                     if (leftHeight > 0) {
//                         PDF.addPage()
//                     }
//                 }
//             }
//             PDF.save(title + '.pdf')
//         })
//     }
// }